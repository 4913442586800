import customSelectStyles from '../../sass/includes/custom_float.scss';
let commons = require('../utils/commons');

function customFloatAnimation(floatNodes) {
    for(let i = 0; i < floatNodes.length; i++) {
       let floatNode = floatNodes.item(i);
       if (commons.hasClassName(floatNode, 'float-up')) {
           commons.removeClassName(floatNode, 'float-up');
       }
       if (!commons.hasClassName(floatNode, 'float-down')) {
           commons.addClassName(floatNode, 'float-down');
       }
    }
    setTimeout(function() {
        for(let i = 0; i < floatNodes.length; i++) {
           let floatNode = floatNodes.item(i);
           if (commons.hasClassName(floatNode, 'float-down')) {
               commons.removeClassName(floatNode, 'float-down');
           }
           if (!commons.hasClassName(floatNode, 'float-up')) {
               commons.addClassName(floatNode, 'float-up');
           }
        }
    }, 800);
}

document.addEventListener('DOMContentLoaded', function() {
    let customFloatNodes = document.getElementsByClassName('custom-float');
    customFloatAnimation(customFloatNodes);
    let timerId = setInterval(customFloatAnimation, 1600, customFloatNodes);
});
