import baseStyles from '../sass/base.scss';
let commons = require('./utils/commons');
const WOW = require('wowjs');
import mobileMenuJS from './includes/mobile_menu.js';
import fancyboxJS from './fancybox/jquery.fancybox.min.js';
import selectricJS from './selectric/jquery.selectric.min.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customFloatJS from './includes/custom_float.js';
import customSelectJS from './includes/custom_select.js';
import PerfectScrollbar from 'perfect-scrollbar';
import perfectScrollbarStyles from '../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

const customScrollNodes = document.getElementsByClassName('custom-scroll');
for(let i = 0; i < customScrollNodes.length; i++) {
    let customScrollNode = customScrollNodes.item(i);
    const ps = new PerfectScrollbar(customScrollNode);
}

$('.selectric-select').selectric();

window.wow = new WOW.WOW(
    {

    }
);
window.wow.init();
