import customSelectStyles from '../../sass/includes/custom_select.scss';
let commons = require('../utils/commons');

function CustomSelect(customSelectNode, scrollAmountToggle=10) {
    // nodes
    this.baseNode = customSelectNode;
    this.hiddenInput = this.baseNode.getElementsByTagName('input');
    if (this.hiddenInput) {
        this.hiddenInput = this.hiddenInput[0];
    } else {
        console.error('CustomSelect: No input found in base node of custom select');
        return false;
    }
    this.selectBoxNode = this.baseNode.getElementsByClassName('select-box');
    if (this.selectBoxNode) {
        this.selectBoxNode = this.selectBoxNode[0];
    } else {
        console.error('CustomSelect: No select box node found in base node of custom select');
        return false;
    }
    this.currentValueNode = this.baseNode.getElementsByClassName('current-value');
    if (this.currentValueNode) {
        this.currentValueNode = this.currentValueNode[0];
    } else {
        console.error('CustomSelect: No current value node found in base node of custom select');
        return false;
    }
    this.valuesListNode = this.baseNode.getElementsByClassName('custom-list');
    if (this.valuesListNode) {
        this.valuesListNode = this.valuesListNode[0];
    } else {
        console.error('CustomSelect: No values list node found in base node of custom select');
        return false;
    }
    this.values = this.valuesListNode.getElementsByTagName('a');
    // set "scroll" class for long list of items
    if (this.values.length > scrollAmountToggle) {
        commons.addClassName(this.baseNode, "scroll");
    }
    // params
    this.placeholderText = this.hiddenInput.getAttribute('placeholder');
    this.currentValue = this.hiddenInput.getAttribute('value');
    this.currentText = this.placeholderText;
    this.isSelectOpen = false;
    // events
    this.currentValueNode.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggle();
    }.bind(this));
    for(let i = 0; i < this.values.length; i++) {
       let valueNode = this.values.item(i);
       valueNode.addEventListener('click', function(e) {
           e.preventDefault();
           const linkNode = e.target;
           const text = linkNode.innerHTML;
           const value = linkNode.dataset.value;
           this.setCurrentValueAndText(value, text);
           this.toggle();
       }.bind(this));
    }
    // methods
    this.setCurrentValueAndText = function(value, text) {
        this.hiddenInput.setAttribute('value', value);
        // trigger onchage on hidden input
        if ("createEvent" in document) {
            var evt = document.createEvent("HTMLEvents");
            evt.initEvent("change", false, true);
            this.hiddenInput.dispatchEvent(evt);
        }
        else {
            this.hiddenInput.fireEvent("onchange");
        }
        // set text
        this.currentValueNode.getElementsByClassName('text')[0].innerHTML = text;
    }
    this.toggle = function() {
        if (this.isSelectOpen) {
            commons.removeClassName(this.selectBoxNode, 'open');
            commons.addClassName(this.selectBoxNode, 'in-transition');
            setTimeout(function () {
                commons.removeClassName(this.selectBoxNode, 'in-transition');
            }.bind(this), 400)
            this.isSelectOpen = false;
        } else {
            commons.addClassName(this.selectBoxNode, 'open');
            this.isSelectOpen = true;
        }
    }
    // init
    this.setCurrentValueAndText('', this.placeholderText);
    for(let i = 0; i < this.values.length; i++) {
       let valueNode = this.values.item(i);
       const text = valueNode.innerHTML;
       const value = valueNode.dataset.value;
       if (value === this.currentValue) {
           this.setCurrentValueAndText(value, text);
       }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const customSelects = document.getElementsByClassName('custom-select');
    for(let i = 0; i < customSelects.length; i++) {
       let customSelectNode = customSelects.item(i);
       let customSelectObj = new CustomSelect(customSelectNode);
    }
});
